import React from "react"
import Contact from "../components/Contact"
import '../css/global.css'
import bgImage from '../images/bg-image-3.jpg'

export default function Home() {
  return (
    <div className="h-screen w-screen bg-cover bg-center" style={ { backgroundImage: `url(${bgImage})` }}>
      <div className="bg-black bg-opacity-75">
        <div className="container mx-auto">
          <div className="w-full max-w mx-auto flex h-screen items-center">
            <div className="flex w-2/3">
              <div className="text-white">
                <h1 className="text-bold text-6xl uppercase font-bold">
                  SOFTWARE DEVELOPMENT &amp; REMEDIATION
                </h1>
                <p className="text-lg">
                  From greenfield applications to modernizing legacy projects,
                  we're the ones to bring your application ideas to life.
                </p>
              </div>
            </div>
            <div className="w-1/3 bg-white rounded-md shadow-md p-6">
              <Contact></Contact>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
