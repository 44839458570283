import React, { Component } from "react";

export default function Contact() {
  return (
    <div>
      <h1 className="text-2xl mb-6">Contact Us</h1>
      <p className="mb-6">
        If you'd like us to help with your next project or a project that's currently in progress please complete the form below.
      </p>
      <form method="POST" name="contact" netlify netlify-honeypot="bot-field">
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="name">
          Name
        </label>
        <input name="name" type="text" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" />
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="email">
          Email
        </label>
        <input name="email" type="email" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" />
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="subject">
          Subject
        </label>
        <input name="subject" type="text" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" />
        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="description">
          Description
        </label>
        <textarea name="Description" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"></textarea>
        <input type="hidden" name="form-name" value="contact" />
        <button type="submit" className="mt-6 bg-blue-500 hover:bg-blue-700 cursor-pointer text-white font-bold py-2 px-4 rounded-md shadow-md w-full">Submit</button>
      </form>
    </div>
  )
}